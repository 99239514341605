import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import * as config from '../../../config/site';

// appsolut base components
import ApiDataService from '../../api/ContactDataService';
import StyledBox from '../../components/controls/StyledBox';
import StyledButton from '../../components/controls/StyledButton';
import FormInput from '../../components/controls/FormInput';
import FormMessage from './FormMessage';
import ToastMessage from './ToastMessage';
//import { DisplayFormikState } from '../../utils/formik';

function ContactForm() {
  // parameter management
  const initialFormMessage = 'Anfrage fehlgeschlagen. Bitte prüfen Sie Ihre Eingabe.';
  const initialSuccessMessage = 'Ihre Anmeldung wurde erfolgreich durchgeführt';
  const initialErrorMessage = 'Ein Fehler ist aufgetreten, please retry.';
  // state
  const [showFormMessage, setShowFormMessage] = useState(false);
  const [formMessage] = useState(initialFormMessage);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage] = useState(initialSuccessMessage);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const [loading, setLoading] = useState(false);

  // event handler
  const toggleSuccess = () => setShowSuccess(!showSuccess);
  const toggleError = () => setShowError(!showError);
  const sendFormDataToServer = async (payload) => {
    setLoading(true);
    try {
      const flowResult = await ApiDataService.sendContactRequest(payload);
      setLoading(false);
      //setShowSuccess(true);
      setShowFormMessage(false);

      // navigate to caller page
      const navlink = config.pages.thankyou.contact;
      navigate(navlink);
    } catch (error) {
      setLoading(false);
      setShowFormMessage(true);
      setShowSuccess(false);
    }
  };

  // data management
  const formInitialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };
  const formValidation = Yup.object({
    name: Yup.string().max(50, 'Max. 50 Zeichen').required('Pflichtfeld'),
    email: Yup.string()
      .email('Geben Sie bitte eine gültige E-Mail Addresse ein')
      .required('Pflichtfeld'),
    subject: Yup.string().max(100, 'Max. 100 Zeichen').required('Pflichtfeld'),
    message: Yup.string(),
  });

  // rendering
  return (
    <Fragment>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidation}
        onSubmit={(values, actions) => {
          try {
            const payload = { ...values };
            sendFormDataToServer(payload);
            actions.resetForm();
          } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
            setShowError(true);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <StyledBox pt={0.5} pb={3} px={3}>
              <Grid container>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="name"
                    label="Name"
                    placeholder="Vor und Zuname"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="email"
                    label="E-Mail"
                    placeholder="Ihre E-Mail Adresse"
                    type="email"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="subject"
                    label="Mein Thema"
                    placeholder="Worum geht's?"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="message"
                    label="Nachricht"
                    placeholder="Ihre Nachricht..."
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
              <FormMessage message={formMessage} open={showFormMessage} />
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent="flex-end"
                textAlign="right"
                ml="auto"
              >
                <StyledButton
                  type="submit"
                  disabled={formik.isSubmitting || loading}
                  variant="gradient"
                  color="info"
                >
                  Absenden
                </StyledButton>
              </Grid>
            </StyledBox>
            {/* <DisplayFormikState {...formik} /> */}
          </form>
        )}
      </Formik>
      <ToastMessage
        message={errorMessage}
        open={showError}
        severity={'error'}
        onClose={toggleError}
      />
      <ToastMessage message={successMessage} open={showSuccess} onClose={toggleSuccess} />
    </Fragment>
  );
}

export default ContactForm;
