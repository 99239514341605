import * as ApiService from '../utils/axios';

class FlowService {
  sendContactRequest = (payload) => {
    return new Promise((resolve, reject) => {
      ApiService.toFlow
        .post('/ix63x47ovfaffe8wpd7eqqfmw9ap3gsa', payload)
        .then((response) => {
          if (response.status === 200) resolve(response.data);
          else reject({ code: response.code, status: response.status, message: response.message });
        })
        .catch((error) => reject(error));
    });
  };
}

const flowService = new FlowService();
export default flowService;
